import { useDispatch } from "react-redux";
import { motion } from "framer-motion";

import { LogoutIcon } from "@heroicons/react/outline";
import { userSignOut } from "../../appRedux/actions/Auth";

import TooltipV3 from "../../components/dataDisplay/TooltipV3";

const SidebarFooter = ({ controlText, controlIconText, isSidebarActive }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex-shrink-0 flex cursor-pointer">
      <div className="flex items-center">
        <div className="mr-[28px] pb-[40px]">
          <a
            onClick={() => dispatch(userSignOut())}
            className="text-sm font-medium flex cursor-pointer"
          >
            {" "}
            <motion.p animate={controlIconText}>
              {isSidebarActive ? (
                <LogoutIcon
                  className="ml-[10px] h-[22px] w-[22px] text-gray-500 group-hover:text-gray-700"
                  aria-hidden="true"
                />
              ) : (
                <TooltipV3 title={"تسجيل الخروج"}>
                  <LogoutIcon
                    className="ml-[10px] h-[22px] w-[22px] text-gray-500 group-hover:text-gray-700"
                    aria-hidden="true"
                  />
                </TooltipV3>
              )}
            </motion.p>
            <motion.p animate={controlText}>تسجيل الخروج</motion.p>
          </a>
          {/* <p className="text-sm font-medium text-gray-500 ">مشاهدة الملف الشخصي</p> */}
        </div>
      </div>
    </div>
  );
};

export default SidebarFooter;
