import React from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { arabicStringChecker } from "../../util/arabicStringChecker";
import MediaServices from "../../services/mediaservices/MediaServices";
import { Popover } from "../../components/dataDisplay/Popover";
import { GROUP_ID, BUSINESS_DATA } from "../../constants/ActionTypes";
import { updateLogo, updateThemeColor } from "../../appRedux/actions/Setting";
import { applyTheme } from "../../util/theme/applyTheme";

const SidebarHeader = (props) => {
  const dispatch = useDispatch();

  const { businessData } = useSelector(({ auth }) => auth);
  const { businessGroups } = useSelector(({ auth }) => auth);

  const renderLogo = (logoRef = businessData.logoReference) => {
    const logo = MediaServices.getMediaUrl(logoRef);

    return logo ? (
      <img
        alt="logo"
        src={logo}
        className="w-[38px] h-[38px] rounded-lg object-contain"
      />
    ) : (
      <svg
        className="w-[38px] h-[38px] fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
      </svg>
    );
  };

  const renderBusinessGroups = (close) => {
    const renderedBusinessGroups = businessGroups.filter(
      (b) => b.id !== businessData.id
    );

    if (renderedBusinessGroups.length === 0) {
      return null;
    } else {
      return (
        <div className="w-[250px] p-4 rounded-xl bg-gray-250">
          <div className="mt-6 w-full bg-white rounded-lg">
            {renderedBusinessGroups.map((b, i) => {
              return (
                <div
                  key={b.id}
                  className={`w-full px-4 py-2 flex justify-start items-center rounded-md cursor-pointer ${
                    renderedBusinessGroups.length !== i + 1 && "border-b"
                  }`}
                  onClick={() => {
                    localStorage.setItem("group_id", b.id);
                    localStorage.setItem("business_data", JSON.stringify(b));
                    localStorage.setItem(
                      "logo",
                      JSON.stringify(b.logoReference)
                    );

                    dispatch({ type: GROUP_ID, payload: b.id });
                    dispatch({ type: BUSINESS_DATA, payload: b });

                    dispatch(updateLogo(b.logoReference));

                    const color = b.themeColor;

                    applyTheme(color);
                    color && dispatch(updateThemeColor("primary"));

                    localStorage.setItem("theme", color);

                    close();
                  }}
                >
                  <div className="w-fit">{renderLogo(b.logoReference)}</div>
                  <div className="text-[14px] mr-3 font-medium">{b.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="w-full py-2 mt-4 px-2">
      <Popover
        ButtonContent={
          <motion.p
            animate={props.controlContainer}
            className="w-full rounded-md"
          >
            <div className="py-1 w-full flex justify-center items-center">
              <motion.p animate={props.controlLogo}>{renderLogo()}</motion.p>
              <motion.p animate={props.controlText}>
                {" "}
                <div
                  className={`text-[15px] 2xl:text-[16px] ${
                    arabicStringChecker(props.props.businessData.name)
                      ? "text-right"
                      : "text-left"
                  } ps-2 pe-[22px] font-bold text-gray-900 align-middle`}
                  style={{ wordBreak: "break-word" }}
                >
                  {props.props.businessData.name}
                </div>{" "}
              </motion.p>
            </div>
          </motion.p>
        }
        renderContent={(close) => {
          return renderBusinessGroups(close);
        }}
      />
    </div>
  );
};

export default SidebarHeader;
