import React from "react";
import ReactDOM from "react-dom/client";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import "dayjs/locale/ar";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";

import App from "./App.jsx";
import store, { history } from "./appRedux/store";
import setupInterceptors from "./services/setupInterceptors";
import identitySetupInterceptors from "./services/identityServices/IdentitySetupInterceptors";
import mediaSetupInterceptors from "./services/mediaservices/mediaSetupInterceptors";
import contentSetupInterceptors from "./services/contentServecies/contentSetupInterceptors";
import "./index.css";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const router = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <React.StrictMode>
        <ConfigProvider direction="rtl">
          <CacheProvider value={cacheRtl}>
            <App />
          </CacheProvider>
        </ConfigProvider>
      </React.StrictMode>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.createRoot(document.getElementById("root")).render(router);

setupInterceptors();
contentSetupInterceptors();
mediaSetupInterceptors();
identitySetupInterceptors();
