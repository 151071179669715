const AuthTypes = {
  RESET_AUTH: "RESET_AUTH",
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  SET_USER: "SET_USER",
  GET_USER_PROFILE: "GET_USER_PROFILE",
  INIT_URL: "INIT_URL",
  SET_HEADERS: "SET_HEADERS",
  SET_TOKEN: "SET_TOKEN",
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
};

export default AuthTypes;
