import store from "../../appRedux/store";
import { identityApi as api, identityApiV2 } from "../Api";

class BusinessGroupServices {
  getMyBusinessGroup() {
    return api
      .get(`business/BusinessGroup`, {
        headers: { ...store.getState().auth.headers, "x-app-language-id": 1 },
      })
      .then((response) => {
        return response;
      });
  }

  getMyBusinessGroups() {
    return identityApiV2
      .get(`business/BusinessGroup`, {
        headers: { ...store.getState().auth.headers, "x-app-language-id": 1 },
      })
      .then((response) => {
        return response;
      });
  }

  updateMyBusinessGroup(body) {
    return api
      .put(`business/BusinessGroup`, body, {
        headers: { ...store.getState().auth.headers, "x-app-language-id": 1 },
      })
      .then((response) => {
        return response.data.data;
      });
  }
}

export default new BusinessGroupServices();
