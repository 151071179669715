import {
  FETCH_ERROR,
  FETCH_SINGLE_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  REFRESH_DATA_TABLE,
  VOUCHER_COLORS,
  UPDATE_DATA_TABLE,
  SET_ALERT,
  CLEAR_ALERT,
} from "../../constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS,
  };
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error,
  };
};

export const fetchSingleError = (error) => {
  return {
    type: FETCH_SINGLE_ERROR,
    payload: error,
  };
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export function updateDataTable(bool) {
  return (dispatch) => {
    dispatch({ type: UPDATE_DATA_TABLE, payload: bool });
  };
}

export function refreshDataTableFun(bool) {
  return (dispatch) => {
    dispatch({ type: REFRESH_DATA_TABLE, payload: bool });
  };
}

export function changeVoucherColors(colors) {
  return (dispatch) => {
    localStorage.setItem("voucherColors", JSON.stringify(colors));
    dispatch({ type: VOUCHER_COLORS, payload: colors });
  };
}

export function setAlert(payload) {
  return (dispatch) => {
    dispatch({ type: SET_ALERT, payload });
  };
}

export function clearAlert() {
  return { type: CLEAR_ALERT };
}
