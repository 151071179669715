import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import logo from "../../assets/images/logo.png";
import { XIcon } from "@heroicons/react/outline";

import { useDispatch, useSelector } from "react-redux";
import { toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import Sidebar from "./sidebarContainer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SideBar = ({ setMatch, match, theme, logo, businessData }) => {
  const { navCollapsed } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  return (
    <div className="relative z-10">
      <Transition.Root show={navCollapsed} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={navCollapsed}
          onClose={() => dispatch(toggleCollapsedSideNav(true))}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative  flex flex-col max-w-xs bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 ">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none "
                    onClick={() => dispatch(toggleCollapsedSideNav(false))}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon
                      className="h-6 w-6 text-gray-900"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>

              <div className="flex h-full h-screen h-16 bg-gray-900 py-3 ">
                <Sidebar />
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <Sidebar theme={theme} logo={logo} businessData={businessData} />
      </div>
    </div>
  );
};

export default SideBar;
