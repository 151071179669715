import { useState, useEffect, useLayoutEffect, useRef } from "react";
import navigation from "./sidebarContent";
import { RiMenuUnfoldLine, RiMenuFoldLine } from "react-icons/ri";
import { Link, useHistory, useLocation } from "react-router-dom";
import SidebarFooter from "./sidebarFooter";
import SidebarHeader from "./sidebarHeader";
import { motion, useAnimation } from "framer-motion";
import SVG from "react-inlinesvg";

import TooltipV3 from "../../components/dataDisplay/TooltipV3";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Sidebar = (props) => {
  const sidebarItemsRef = useRef();

  const history = useHistory();
  const [navContent, setNavContant] = useState(navigation);
  const controls = useAnimation();
  const [active, setActive] = useState(false);

  const controlText = useAnimation();
  const controlIconText = useAnimation();
  const controlLogo = useAnimation();
  const controlContainer = useAnimation();
  const location = useLocation();
  const newSelect = (prop) => () => {
    const content = navContent.map((item) => {
      if (item.current === true) {
        return { ...item, current: false };
      } else return item;
    });
    content[prop] = { ...content[prop], current: true };
    setNavContant(content);
    history.push(content[prop].href);
  };

  const showMore = () => {
    controls.start({
      width: "210px",
      transition: { duration: 0.001 },
    });
    controlText.start({
      opacity: 1,
      display: "block",
      transition: { delay: 0.3 },
      flexBasis: "139px",
      // flexGrow: 1,
    });
    controlIconText.start({});
    controlLogo.start({
      margin: "0",
      marginRight: "28px",
      flexBasis: "38px",
      // border: "1px solid #dadeea",
      // borderRadius: "8px",
    });
    controlContainer.start({
      border: "1px solid #dadeea",
      backgroundColor: "rgb(249, 250, 251)",
    });
    setActive(true);
  };

  const showLess = () => {
    controls.start({
      width: "75px",
      transition: { duration: 0.001 },
    });

    controlText.start({
      opacity: 0,
      display: "none",
    });

    controlIconText.start({
      textAlign: "center",
    });
    controlLogo.start({
      textAlign: "center",
      marginRight: "auto",
      margin: "auto",
      flexBasis: "38px",
      // border: "1px solid #dadeea",
      // borderRadius: "8px",
    });
    controlContainer.start({
      border: "none",
      backgroundColor: "transparent",
    });

    setActive(false);
  };

  useEffect(() => {
    showMore();
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1200) {
      showMore();
    } else {
      showLess();
    }

    const resizeListener = (event) => {
      if (event.target.innerWidth > 1200) {
        showMore();
      } else {
        showLess();
      }
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useLayoutEffect(() => {
    const style = document.createElement("style");

    style.innerHTML = `
      #sidebar-items svg {
        fill: none;
      }
    `;

    if (sidebarItemsRef?.current) {
      sidebarItemsRef.current.append(style);
    }
  }, [sidebarItemsRef]);

  return (
    <>
      <motion.div
        animate={controls}
        className="flex flex-col items-center h-full animate duration-300 text-gray-900 dark bg-white print-hidden max-w-60 min-h-screen"
      >
        <div
          className={`h-20 px-4 bg-gray-250 w-full  flex ${
            active ? "justify-end" : "justify-center"
          } items-center`}
        >
          {active && (
            <RiMenuUnfoldLine
              onClick={showLess}
              className="w-7 h-6 text-primary cursor-pointer"
            />
          )}
          {!active && (
            <RiMenuFoldLine
              onClick={showMore}
              className="w-7 h-6 text-primary cursor-pointer"
            />
          )}
        </div>
        <SidebarHeader
          controlLogo={controlLogo}
          controlText={controlText}
          controlContainer={controlContainer}
          props={props}
        />

        <div
          className={`w-full ${
            active && "ps-[28px]"
          } justify-between h-full flex flex-col`}
        >
          <div
            ref={sidebarItemsRef}
            id="sidebar-items"
            className="flex flex-col items-center w-full mt-1 border-gray-700"
          >
            <nav className="space-y-2 w-full flex flex-col justify-center items-center">
              {navContent.map((item, i) => {
                let isPathnameRelated = false;

                item.pathnameIdentifiers.some((p) => {
                  if (
                    location.pathname.split("/")[1] &&
                    p === location.pathname.split("/")[1]
                  ) {
                    isPathnameRelated = true;

                    return true;
                  }

                  return false;
                });

                return (
                  <Link
                    key={i}
                    to={{
                      pathname: item.href,
                      state: { from: location },
                    }}
                    className={classNames(
                      isPathnameRelated
                        ? `border-e-4 rounded-sm border-primary text-primary`
                        : `text-[#a7b1c2] hover:text-primary`,
                      `group flex ${
                        !active && "justify-center"
                      } items-center text-[14px] 2xl:text-[15px] font-bold h-[30px] w-full`
                    )}
                  >
                    <motion.p animate={controlIconText}>
                      {active ? (
                        <div
                          onClick={newSelect(i)}
                          key={item.name}
                          className={classNames(
                            `group py-[11px] text-[14px] 2xl:text-[15px] font-bold`
                          )}
                        >
                          <div
                            className={`w-4 h-4 ${
                              isPathnameRelated
                                ? "text-primary"
                                : `text-[#a7b1c2] group-hover:text-primary`
                            }`}
                          >
                            <SVG
                              src={item.icon}
                              width={"100%"}
                              height={"100%"}
                              preProcessor={(code) =>
                                code
                                  .replace(
                                    /fill=".*?"/g,
                                    `fill=${
                                      item.icon === "/assets/icons/settings.svg"
                                        ? '"none"'
                                        : '"currentcolor"'
                                    }`
                                  )
                                  .replace(
                                    /stroke=".*?"/g,
                                    `stroke="currentcolor"`
                                  )
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <TooltipV3 title={item.name}>
                          <div
                            onClick={newSelect(i)}
                            key={item.name}
                            className={classNames(
                              `group flex items-center text-[13px] font-bold`
                            )}
                          >
                            <div
                              className={`w-4 h-4 ${
                                isPathnameRelated
                                  ? "text-primary"
                                  : `text-[#a7b1c2] group-hover:text-primary`
                              }`}
                            >
                              <SVG
                                src={item.icon}
                                width={"100%"}
                                height={"100%"}
                                preProcessor={(code) =>
                                  code
                                    .replace(
                                      /fill=".*?"/g,
                                      `fill=${
                                        item.icon ===
                                        "/assets/icons/settings.svg"
                                          ? '"none"'
                                          : '"currentcolor"'
                                      }`
                                    )
                                    .replace(
                                      /stroke=".*?"/g,
                                      `stroke="currentcolor"`
                                    )
                                }
                              />
                            </div>
                          </div>
                        </TooltipV3>
                      )}
                    </motion.p>
                    {active && (
                      <div className="ps-2 py-[11px]">
                        <motion.p animate={controlText}>{item.name}</motion.p>
                      </div>
                    )}
                  </Link>
                );
              })}
            </nav>
          </div>
          {/* <div className="w-full ps-4 leading-9 mb-4">
          <div className="ps-2 self-start">
            <span className={` font-bold  text-normal text-${props.theme}`}>
              تطبيق وجيز
            </span>
          </div>
          <div className=" ps-2 self-start text-xs font-bold leading-5">
            <span> مع تطبيق وجيز صار التعلُّم ممتعاً دون جهد أو وقت طويل. استمع الى ملخصات الكتب في طريقك الى المنزل أو أثناء التمرين </span>
          </div>
        </div> */}
        </div>
        <div className="flex items-center  w-full mt-auto  ">
          <SidebarFooter
            controlText={controlText}
            controlIconText={controlIconText}
            isSidebarActive={active}
          />
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
