import { Popover as PopoverHeadlessUi, Transition } from "@headlessui/react";

export const Popover = ({
  ButtonContent,
  renderContent,
  placement = "bottom",
}) => {
  return (
    <PopoverHeadlessUi>
      {({ open }) => (
        <>
          <PopoverHeadlessUi.Button className="w-full flex justify-between items-center !outline-none !border-none">
            {ButtonContent}
            {/* <ChevronDownIcon className={open ? "rotate-180 transform" : ""} /> */}
          </PopoverHeadlessUi.Button>
          <Transition
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverHeadlessUi.Panel
              anchor={placement}
              className="absolute z-10"
            >
              {({ close }) => renderContent(close)}
            </PopoverHeadlessUi.Panel>
          </Transition>
        </>
      )}
    </PopoverHeadlessUi>
  );
};
