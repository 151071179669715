export const Identity_Microservice_URL =
  process.env.REACT_APP_Identity_Microservice_URL;
export const Content_Microservice_URL =
  process.env.REACT_APP_Content_Microservice_URL;
export const Payment_Microservice_URL =
  process.env.REACT_APP_Payment_Microservice_URL;
export const Engagement_Microservice_URL =
  process.env.REACT_APP_Engagement_Microservice_URL;
export const Media_Microservice_URL =
  process.env.REACT_APP_Media_Microservice_URL;
