import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SINGLE_ERROR,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  REFRESH_DATA_TABLE,
  VOUCHER_COLORS,
  UPDATE_DATA_TABLE,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  SET_ALERT,
  CLEAR_ALERT,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  error: { type: "", msg: null },
  loading: false,
  singleError: "",
  message: "",
  navCollapsed: false,
  width: window.innerWidth,
  pathname: "",
  updateTable: false,
  refreshDataTable: false,
  voucherColors: JSON.parse(localStorage.getItem("voucherColors")) || [],
  alert: {
    show: false,
    target: "page",
    type: "ERROR",
    message: null,
  },
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false,
      };
    }
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case TOGGLE_COLLAPSED_NAV: {
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    }
    case FETCH_START: {
      return {
        ...state,
        error: "",
        message: "",
        loading: true,
        singleError: "",
      };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: "", message: "", loading: false };
    }
    case SHOW_MESSAGE: {
      return { ...state, error: "", message: action.payload, loading: false };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload, message: "" };
    }
    case FETCH_SINGLE_ERROR: {
      return {
        ...state,
        loading: false,
        singleError: action.payload,
        message: "",
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        singleError: "",
        error: "",
        message: "",
      };
    }
    case UPDATE_DATA_TABLE:
      return {
        ...state,
        updateTable: action.payload,
      };
    case REFRESH_DATA_TABLE:
      return {
        ...state,
        refreshDataTable: action.payload,
      };
    case VOUCHER_COLORS:
      return {
        ...state,
        voucherColors: action.payload,
      };
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case CLEAR_ALERT:
      return {
        ...state,
        alert: INIT_STATE.alert,
      };
    default:
      return state;
  }
};

export default reducer;
