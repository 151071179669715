import { mediaApi as api } from "../Api";
// import AlertActions from "../store/actions/alertActions";
import store from "../../appRedux/store";
import { Media_Microservice_URL } from "../../constants/Constants";

class MediaServices {
  getMediabyId(id) {
    return api
      .get(`Media/public/${id}`, {
        ...store.getState().auth.headers,
        responseType: "blob",
      })
      .then((response) => {
        const downloadUrl = URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );
        return downloadUrl;
      });
  }
  getMediaUrl(id) {
    return Media_Microservice_URL + `media/v1/Media/public/${id}`;
  }
  uploadMedia(file) {
    return api
      .post(`Media/media`, file, {
        // headers: {
        // ...store.getState().auth.headers,
        // "x-app-language-id": 1,
        // },
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new MediaServices();
