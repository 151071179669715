const navigation = [
  {
    name: "المهارات",
    href: "/skills",
    icon: "/assets/icons/skills.svg",
    current: true,
    pathnameIdentifiers: ["skills"],
  },
  {
    name: "الفريق",
    href: "/team",
    icon: "/assets/icons/users.svg",
    current: true,
    pathnameIdentifiers: ["team"],
  },
  {
    name: "الأقسام",
    href: "/departments",
    icon: "/assets/icons/department.svg",
    current: true,
    pathnameIdentifiers: ["departments"],
  },
  {
    name: "الإحصائيات",
    href: "/dashboard",
    icon: "/assets/icons/stats.svg",
    current: false,
    pathnameIdentifiers: ["dashboard", "usersProgress"],
  },
  {
    name: "الاشتراكات",
    href: "/users",
    icon: "/assets/icons/subscription.svg",
    current: false,
    pathnameIdentifiers: ["users", "vouchers"],
  },
  {
    name: "الاعدادات",
    href: "/Settings",
    icon: "/assets/icons/settings.svg",
    current: false,
    pathnameIdentifiers: ["Settings"],
  },
];

export default navigation;
