import { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import App from "../../routes/index.jsx";
import { useSelector } from "react-redux";
import SideBar from "../sidebar/index";

const MainApp = () => {
  const [match, setMatch] = useState(useRouteMatch());
  const { themeColor, logo } = useSelector(({ settings }) => settings);
  const businessData = useSelector(({ auth }) => auth.businessData);

  return (
    <div dir="rtl" className="h-screen flex overflow-hidde bg-wihte ">
      <SideBar
        match={match}
        setMatch={setMatch}
        theme={themeColor}
        logo={logo}
        businessData={businessData}
      />
      <div className="flex flex-col w-full flex-1 overflow-hidden">
        <main className="flex-1 overflow-y-auto overflow-x-hidden focus:outline-none">
          <div className="z-0 relative w-full pl-[35px] 2xl:pl-[40px]">
            <App
              match={match}
              theme={themeColor}
              logo={logo}
              businessData={businessData}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default MainApp;
