import Tooltip from "@mui/material/Tooltip";

const TooltipV3 = ({ placement = "top", title, children }) => {
  return (
    <Tooltip
      placement={placement}
      title={
        <div
          className={`relative max-w-xs px-[6px] pt-[6px] pb-[3px] border-0 z-50 font-normal leading-normal text-sm bg-white no-underline rounded-[3px] shadow-[0px_2px_5px_0px_rgba(0,_0,_0,_0.14);]`}
        >
          <div className="w-full">
            <div className="text-black">{title}</div>
          </div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

export default TooltipV3;
