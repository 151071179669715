import axios from "axios";
// store
import store from "../appRedux/store";
import {
  Content_Microservice_URL,
  Identity_Microservice_URL,
  Media_Microservice_URL,
  Engagement_Microservice_URL,
  Payment_Microservice_URL,
} from "../constants/Constants";

let apiSheredValues = {
  headers: store.getState().auth.headers,
  params: {
    timestamp: `${new Date().getTime()}`,
  },
};
export const contentApi = axios.create({
  ...apiSheredValues,
  baseURL: Content_Microservice_URL + "content/v1/",
});
export const identityApi = axios.create({
  ...apiSheredValues,
  baseURL: Identity_Microservice_URL + "identity/v1/",
});
export const identityApiV2 = axios.create({
  ...apiSheredValues,
  baseURL: Identity_Microservice_URL + "identity/v2/",
});
export const unAuthenticatedIdentityApi = axios.create({
  ...apiSheredValues,
  baseURL: Identity_Microservice_URL + "identity/v1/",
});
export const mediaApi = axios.create({
  ...apiSheredValues,
  baseURL: Media_Microservice_URL + "media/v1/",
});
export const engagementApi = axios.create({
  ...apiSheredValues,
  baseURL: Engagement_Microservice_URL,
});
export const paymentApi = axios.create({
  ...apiSheredValues,
  baseURL: Payment_Microservice_URL,
});

const Api = axios.create({ ...apiSheredValues });
export default Api;
